
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
// ADD YOUR OWN FIREBASE CONFIG HERE
const firebaseConfig = {
  apiKey: "AIzaSyD9WWYyCe_UAC8KllpIqefIpGWd-rWVX4s",
  authDomain: "phishnet-security.firebaseapp.com",
  projectId: "phishnet-security",
  storageBucket: "phishnet-security.appspot.com",
  messagingSenderId: "921912346611",
  appId: "1:921912346611:web:416593a6fad1c65f1db486",
  measurementId: "G-QM58CP0986"
};

// Initialize Firebase app
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
const auth = getAuth(firebaseApp);
const googleAuthProvider = new GoogleAuthProvider();

// Initialize Cloud Firestore
export const db = getFirestore(firebaseApp);
export { auth, googleAuthProvider };
